
* {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: top;
  background: transparent;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.hidden {
  display: none !important;
}

.mobile {
  display: none !important;
}

.text-center {
  text-align: center;
}

.brand-logo {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 205px;
  height: 48px;
  background: url("../images/logo-color.svg") 0 0 no-repeat;
  background-size: cover;
}

.brand-logo--white {
  background: url("../images/logo-color.svg") 0 0 no-repeat;
  background-size: cover;
}

.pad {
  display: block;
  position: relative;
  box-sizing: border-box;
}

.box-avatar {
  display: block;
  position: relative;
  box-sizing: border-box;
  border-radius: 100%;
  overflow: hidden;
}
.box-avatar img {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.box-content {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.box-content strong {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.box-content p {
  display: block;
  position: relative;
  box-sizing: border-box;
}

.title-main {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #004785;
}

.meta-date {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #687784;
}
.meta-date img {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  margin-top: -3px;
  margin-right: 5px;
}

.tag {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  margin-left: 15px;
  width: 36px;
  height: 36px;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
}

.tag--circle {
  border-radius: 100%;
}

.tag--white {
  background: white;
  color: #353535;
}

.btn-play {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 88px;
  height: 88px;
  background: url("../images/icons/icon-play.svg") 0 0 no-repeat;
  background-size: cover;
  z-index: 3;
  top: calc(50% - 44px);
  left: calc(50% - 44px);
}

.btn-link {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #f07778;
  margin: 0 5px;
  margin-right: 15px;
  text-decoration: underline;
}
.btn-link:after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  z-index: 1;
  width: 10px;
  height: 7px;
  right: -15px;
  top: 8px;
  background: url("../images/icons/icon-arrow-small-red.svg") 0 0 no-repeat;
  background-size: cover;
}
.btn-link:hover {
  color: #f07778;
  opacity: 0.75;
}

.btn-square {
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  padding: 16px 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.btn-square--red {
  color: #737373;
  background: #FFF;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

.section {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.section:after {
  content: "";
  display: table;
  clear: both;
}

.section--inbox {
  background-color: #004785;
  min-height: 100vh;
}
.section--inbox .pad {
  margin: 74px auto;
  background: white;
  max-width: 800px;
}
.section--inbox .pad__header {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 22px 60px 22px 47px;
  background-color: #EDF5F7;
}
.section--inbox .pad__header:after {
  content: "";
  display: table;
  clear: both;
}
.section--inbox .pad__header .brand-logo {
  float: left;
}
.section--inbox .pad__header .title-main {
  float: right;
  margin-top: 5px;
}
.section--inbox .pad__header .title-main img {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  margin-right: 15px;
}
.section--inbox .pad__header--red {
  padding: 22px 47px 22px 47px;
  background-color: #f07778;
}
.section--inbox .pad__header--red .title-main {
  color: white;
}
.section--inbox .pad__list {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.section--inbox .pad__list .item {
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  padding: 21px 26px 12px 26px;
}
.section--inbox .pad__list .item:after {
  content: "";
  display: table;
  clear: both;
}
.section--inbox .pad__list .item:nth-child(even) {
  background-color: #EDF5F7;
}
.section--inbox .pad__list .item:hover .box-avatar {
  border-color: #00aeef;
  border-width: 2px;
}
.section--inbox .pad__list .item:last-child {
  padding-bottom: 30px;
}
.section--inbox .pad__list .item .box-avatar {
  float: left;
  margin-right: 30px;
  width: 131px;
  height: 131px;
  background-size: 131px 131px;
  padding: 11px;
  border: solid 1px #A7C0D3;
}
.section--inbox .pad__list .item .box-content {
  float: left;
  width: calc(100% - 131px - 30px);
  padding-top: 20px;
}
.section--inbox .pad__list .item .box-content strong {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #00aeef;
}
.section--inbox .pad__list .item .box-content p {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #004785;
}
.section--inbox .pad__content {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 40px 20px 40px 20px;
}
.section--inbox .pad__content:after {
  content: "";
  display: table;
  clear: both;
}
.section--inbox .pad__content .box-avatar {
  margin: 0 auto 30px;
  max-width: 328px;
  height: 328px;
  background-size: cover !important;
  width: 100%;
  padding: 24px;
  border: solid 2px #4ca5f3;
}
.section--inbox .pad__content .box-content {
  text-align: center;
}
.section--inbox .pad__content .box-content strong {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #4ca5f3;
  margin-bottom: 15px;
}
.section--inbox .pad__content .box-content p {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #303336;
}
.section--inbox .pad__video {
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.section--inbox .pad__video .video-wrapper {
  position: relative;
  padding-bottom: 57.25%;
  height: 0;
}
.section--inbox .pad__video .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.section--inbox .pad__video .video-overlay {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(240, 119, 120, 0.55);
  background-size: 85px 85px;
  z-index: 2;
}
.section--inbox .pad__video img {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  z-index: 1;
  display: none;
}
.section--inbox .pad__message {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 35px 50px 35px 60px;
}
.section--inbox .pad__message:after {
  content: "";
  display: table;
  clear: both;
}
.section--inbox .pad__message article {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 20px;
}
.section--inbox .pad__message article:after {
  content: "";
  display: table;
  clear: both;
}
.section--inbox .pad__message article h1 {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
  color: #737373;
  margin-bottom: 20px;
  margin-top: 0;
}
.section--inbox .pad__message article p {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #575d63;
  margin-bottom: 25px;
}
.section--inbox .pad__message article p img {
  display: block;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  margin: 0 auto 40px;
}
.section--inbox .pad__message article ul {
  display: block;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.section--inbox .pad__message article ul li {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #303336;
  font-style: italic;
  letter-spacing: -0.35px;
  padding-left: 28px;
  margin-bottom: 20px;
}
.section--inbox .pad__message article ul li:before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  z-index: 1;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: #f07778;
  left: 0;
  top: 7px;
}
.section--inbox .pad__polling {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  background: #4ce0b3;
}
.section--inbox .pad__polling strong {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #575d63;
  text-align: center;
  margin-bottom: 40px;
}
.section--inbox .pad__polling .item {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 33px 28px;
  background: White;
  margin-bottom: 20px;
  box-shadow: 0 0 35px 0 rgba(204, 219, 222, 0.75);
  cursor: pointer;
}
.section--inbox .pad__polling .item .ui-radio {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
  cursor: pointer;
}
.section--inbox .pad__polling .item .ui-radio + label {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding-left: 48px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #575d63;
  margin-bottom: 0;
  z-index: 1;
}
.section--inbox .pad__polling .item .ui-radio + label:before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 2px #4ce0b3;
  left: 0;
  top: 2px;
}
.section--inbox .pad__polling .item .ui-radio:checked + label {
  color: #575d63;
}
.section--inbox .pad__polling .item .ui-radio:checked + label:before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 7px #4ce0b3;
  left: 0;
  top: 2px;
  background: #4ce0b3;
}
.section--inbox .pad__polling .item .ui-radio:checked + label + .item__bg {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
}
.section--inbox .pad__polling .btn-square {
  margin: 40px auto 0;
  max-width: 230px;
}

.pad__video * {
  background-size: contain !important;
}
.pad__video iframe * {
  background-size: contain !important;
}

.flowplayer {
  background-size: auto !important;
}

.form-login {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 30px 47px;
}
.form-login .row:after {
  content: "";
  display: table;
  clear: both;
}
.form-login input[type=email] {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border: solid 5px #004785;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 15px;
}
.form-login input[type=password] {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border: solid 5px #004785;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 15px;
}
.form-login input[type=checkbox] {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: solid 1px #004785;
  margin: 0;
  margin-right: 10px;
  background: white;
  border-radius: 0;
}
.form-login input[type=checkbox]:checked {
  background: url("../images/icons/icon-thick.svg") 50% 50% no-repeat;
  background-size: 12px 12px;
}
.form-login input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #004785;
  margin-bottom: 0;
}
.form-login a {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #004785;
  margin-top: 10px;
}
.form-login button {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 375px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  padding: 18px 15px;
  background: #004785;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
.form-login .col-lg-12 {
  margin-top: 20px !important;
}
.form-login label[for=recovery_email] {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #004785;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .container {
    width: auto;
    max-width: 800px;
  }
}
@media (max-width: 568px) {
  .section--inbox .pad {
    margin: 15px auto;
  }
  .section--inbox .pad__header {
    padding: 15px 20px;
  }
  .section--inbox .pad__header .title-main {
    margin-right: 0;
  }
  .section--inbox .pad__list .item {
    padding: 20px 20px 12px 20px;
  }
  .section--inbox .pad__message {
    padding: 25px 20px 25px 20px;
  }
  .section--inbox .pad__polling {
    padding: 25px 15px 25px 15px;
  }
  .form-login {
    padding: 15px 20px;
  }
  .form-login button {
    width: 100%;
  }
}
@media (max-width: 525px) {
  .section--inbox .pad__header .brand-logo {
    width: 203px;
    height: 48px;
  }
  .section--inbox .pad__header .title-main {
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
  }
  .section--inbox .pad__header .title-main img {
    height: 24px;
    width: auto;
    margin-top: -3px;
  }
  .section--inbox .pad__header .title-main .tag {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 24px;
  }
  .section--inbox .pad__list .item .box-avatar {
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
    padding: 6px;
    margin-right: 20px;
  }
  .section--inbox .pad__list .item .box-content {
    width: calc(100% - 100px - 20px);
    padding-top: 10px;
  }
  .section--inbox .pad__list .item .box-content strong {
    font-size: 20px;
    line-height: 24px;
  }
  .section--inbox .pad__list .item .box-content p {
    font-size: 16px;
  }
}
@media (max-width: 440px) {
  .section--inbox .pad__header {
    text-align: center;
  }
  .section--inbox .pad__header .brand-logo {
    float: none;
    margin: 0 auto 15px auto;
  }
  .section--inbox .pad__header .title-main {
    float: none;
    margin: 0 auto;
  }
  .section--inbox .pad__header .title-main img {
    display: none;
  }
  .section--inbox .pad__list .item .box-avatar {
    float: none;
    margin: 0 auto 15px auto;
  }
  .section--inbox .pad__list .item .box-content {
    float: none;
    width: 100%;
    text-align: center;
  }
  .section--inbox .pad__list .item .box-content strong {
    margin: 10px auto;
  }
  .section--inbox .pad__content {
    padding: 25px 15px;
  }
  .section--inbox .pad__content .box-avatar {
    max-width: 250px;
    height: 250px;
    background-size: cover;
    padding: 16px;
    margin-bottom: 20px;
  }
  .section--inbox .pad__content .box-content strong {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  .section--inbox .pad__content .box-content p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .section--inbox .pad__message article h1 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
  }
  .section--inbox .pad__polling strong {
    font-size: 21px;
    margin-bottom: 20px;
  }
  .section--inbox .pad__polling .item {
    padding: 15px 15px;
  }
  .section--inbox .pad__polling .item .ui-radio + label {
    font-size: 14px;
    padding-left: 30px;
  }
  .section--inbox .pad__polling .btn-square {
    font-size: 21px;
  }
}
@media (max-width: 767px) {
  .col-lg-6:first-child {
    margin-bottom: 20px;
  }
}