/*# sourceMappingURL=main.css.map */
* {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: top;
    background: transparent;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.left {
    float: left !important;
}
.right {
    float: right !important;
}
.group {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
.hidden {
    display: none !important;
}
.mobile {
    display: none !important;
}
.text-center {
    text-align: center;
}
.brand-logo {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 205px;
    height: 48px;
    background: url("../images/logo-color.svg") 0 0 no-repeat;
    background-size: cover;
}
.brand-logo--white {
    background: url("../images/logo-color.svg") 0 0 no-repeat;
    background-size: cover;
}
.pad {
    display: block;
    position: relative;
    box-sizing: border-box;
}
.box-avatar {
    display: block;
    position: relative;
    box-sizing: border-box;
    border-radius: 100%;
    overflow: hidden;
    img {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: auto;
    }
}
.box-content {
    display: block;
    position: relative;
    box-sizing: border-box;
    strong {
        display: block;
        position: relative;
        box-sizing: border-box;
    }
    p {
        display: block;
        position: relative;
        box-sizing: border-box;
    }
}
.title-main {
    display: block;
    position: relative;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #004785;
}
.meta-date {
    display: block;
    position: relative;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #687784;
    img {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        margin-top: -3px;
        margin-right: 5px;
    }
}
.tag {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    margin-left: 15px;
    width: 36px;
    height: 36px;
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
}
.tag--circle {
    border-radius: 100%;
}
.tag--white {
    background: white;
    color: #353535;
}
.btn-play {
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 88px;
    height: 88px;
    background: url("../images/icons/icon-play.svg") 0 0 no-repeat;
    background-size: cover;
    z-index: 3;
    top: calc(50% - 44px);
    left: calc(50% - 44px);
}
.btn-link {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #f07778;
    margin: 0 5px;
    margin-right: 15px;
    text-decoration: underline;
    &:after {
        display: block;
        position: absolute;
        box-sizing: border-box;
        content: '';
        z-index: 1;
        width: 10px;
        height: 7px;
        right: -15px;
        top: 8px;
        background: url("../images/icons/icon-arrow-small-red.svg") 0 0 no-repeat;
        background-size: cover;
    }
    &:hover {
        color: #f07778;
        opacity: 0.75;
    }
}
.btn-square {
    display: block;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    padding: 16px 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}
.btn-square--red {
    color: #737373;
    background: #FFF;
}
html {
    overflow-y: scroll;
    overflow-x: hidden;
}
body {
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
}
.section {
    display: block;
    position: relative;
    box-sizing: border-box;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
.section--inbox {
    background-color: #004785;
    min-height: 100vh;
    .pad {
        margin: 74px auto;
        background: white;
        max-width: 800px;
    }
    .pad__header {
        display: block;
        position: relative;
        box-sizing: border-box;
        padding: 22px 60px 22px 47px;
        background-color: #EDF5F7;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        .brand-logo {
            float: left;
        }
        .title-main {
            float: right;
            margin-top: 5px;
            img {
                display: inline-block;
                position: relative;
                box-sizing: border-box;
                margin-right: 15px;
            }
        }
    }
    .pad__header--red {
        padding: 22px 47px 22px 47px;
        background-color: #f07778;
        .title-main {
            color: white;
        }
    }
    .pad__list {
        display: block;
        position: relative;
        box-sizing: border-box;
        .item {
            display: block;
            position: relative;
            box-sizing: border-box;
            background-color: white;
            padding: 21px 26px 12px 26px;
            &:after {
                content: "";
                display: table;
                clear: both;
            }
            &:nth-child(even) {
                background-color: #EDF5F7;
            }
            &:hover {
                .box-avatar {
                    border-color: #00aeef;
                    border-width: 2px;
                }
            }
            &:last-child {
                padding-bottom: 30px;
            }
            .box-avatar {
                float: left;
                margin-right: 30px;
                width: 131px;
                height: 131px;
                background-size: 131px 131px;
                padding: 11px;
                border: solid 1px #A7C0D3;
            }
            .box-content {
                float: left;
                width: calc(100% - 131px - 30px);
                padding-top: 20px;
                strong {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #00aeef;
                }
                p {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: #004785;
                }
            }
        }
    }
    .pad__content {
        display: block;
        position: relative;
        box-sizing: border-box;
        padding: 40px 20px 40px 20px;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        .box-avatar {
            margin: 0 auto 30px;
            max-width: 328px;
            height: 328px;
            background-size: cover !important;
            width: 100%;
            padding: 24px;
            border: solid 2px #4ca5f3;
        }
        .box-content {
            text-align: center;
            strong {
                font-weight: 700;
                font-size: 30px;
                line-height: 36px;
                color: #4ca5f3;
                margin-bottom: 15px;
            }
            p {
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
                color: #303336;
            }
        }
    }
    .pad__video {
        display: block;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        .video-wrapper {
            position: relative;
            padding-bottom: 57.25%;
            height: 0;
            video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .video-overlay {
            display: block;
            position: absolute;
            box-sizing: border-box;
            content: '';
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(240, 119, 120, 0.55);
            background-size: 85px 85px;
            z-index: 2;
        }
        img {
            display: block;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: auto;
            z-index: 1;
            display: none;
        }
    }
    .pad__message {
        display: block;
        position: relative;
        box-sizing: border-box;
        padding: 35px 50px 35px 60px;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        article {
            display: block;
            position: relative;
            box-sizing: border-box;
            padding-bottom: 20px;
            &:after {
                content: "";
                display: table;
                clear: both;
            }
            h1 {
                display: block;
                position: relative;
                box-sizing: border-box;
                font-weight: 700;
                font-size: 36px;
                line-height: 52px;
                color: #737373;
                margin-bottom: 20px;
                margin-top: 0;
            }
            p {
                display: block;
                position: relative;
                box-sizing: border-box;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #575d63;
                margin-bottom: 25px;
                img {
                    display: block;
                    position: relative;
                    box-sizing: border-box;
                    max-width: 100%;
                    height: auto;
                    margin: 0 auto 40px;
                }
            }
            ul {
                display: block;
                position: relative;
                box-sizing: border-box;
                margin-bottom: 30px;
                li {
                    display: block;
                    position: relative;
                    box-sizing: border-box;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: #303336;
                    font-style: italic;
                    letter-spacing: -0.35px;
                    padding-left: 28px;
                    margin-bottom: 20px;
                    &:before {
                        display: block;
                        position: absolute;
                        box-sizing: border-box;
                        content: '';
                        z-index: 1;
                        width: 9px;
                        height: 9px;
                        border-radius: 100%;
                        background: #f07778;
                        left: 0;
                        top: 7px;
                    }
                }
            }
        }
    }
    .pad__polling {
        display: block;
        position: relative;
        box-sizing: border-box;
        padding: 40px;
        background: #4ce0b3;
        strong {
            display: block;
            position: relative;
            box-sizing: border-box;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #575d63;
            text-align: center;
            margin-bottom: 40px;
        }
        .item {
            display: block;
            position: relative;
            box-sizing: border-box;
            padding: 33px 28px;
            background: White;
            margin-bottom: 20px;
            box-shadow: 0 0 35px 0 rgba(204, 219, 222, 0.75);
            cursor: pointer;
            .ui-radio {
                display: block;
                position: absolute;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 2;
                left: 0;
                top: 0;
                cursor: pointer;
                + {
                    label {
                        display: block;
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 48px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: #575d63;
                        margin-bottom: 0;
                        z-index: 1;
                        &:before {
                            display: block;
                            position: absolute;
                            box-sizing: border-box;
                            content: '';
                            z-index: 1;
                            width: 20px;
                            height: 20px;
                            border-radius: 100%;
                            border: solid 2px #4ce0b3;
                            left: 0;
                            top: 2px;
                        }
                    }
                }
                &:checked {
                    + {
                        label {
                            color: #575d63;
                            &:before {
                                display: block;
                                position: absolute;
                                box-sizing: border-box;
                                content: '';
                                z-index: 1;
                                width: 20px;
                                height: 20px;
                                border-radius: 100%;
                                border: solid 7px #4ce0b3;
                                left: 0;
                                top: 2px;
                                background: #4ce0b3;
                            }
                            + {
                                .item__bg {
                                    display: block;
                                    position: absolute;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    background: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .btn-square {
            margin: 40px auto 0;
            max-width: 230px;
        }
    }
}
.pad__video {
    * {
        background-size: contain !important;
    }
    iframe {
        * {
            background-size: contain !important;
        }
    }
}
.flowplayer {
    background-size: auto !important;
}
.form-login {
    display: block;
    position: relative;
    box-sizing: border-box;
    padding: 30px 47px;
    .row {
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
    input[type=email] {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        border: solid 5px #004785;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 18px 15px;
    }
    input[type=password] {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        border: solid 5px #004785;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 18px 15px;
    }
    input[type=checkbox] {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border: solid 1px #004785;
        margin: 0;
        margin-right: 10px;
        background: white;
        border-radius: 0;
        &:checked {
            background: url("../images/icons/icon-thick.svg") 50% 50% no-repeat;
            background-size: 12px 12px;
        }
        + {
            label {
                display: inline-block;
                position: relative;
                box-sizing: border-box;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #004785;
                margin-bottom: 0;
            }
        }
    }
    a {
        display: block;
        position: relative;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-decoration: underline;
        color: #004785;
        margin-top: 10px;
    }
    button {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 375px;
        margin: 0 auto;
        font-weight: 600;
        font-size: 21px;
        line-height: 24px;
        padding: 18px 15px;
        background: #004785;
        color: white;
        text-align: center;
        text-transform: uppercase;
    }
    .col-lg-12 {
        margin-top: 20px !important;
    }
    label[for="recovery_email"] {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #004785;
        margin-bottom: 0;
    }
}
@media (min-width: 768px) {
    .container {
        width: auto;
        max-width: 800px;
    }
}
@media (max-width: 568px) {
    .section--inbox {
        .pad {
            margin: 15px auto;
        }
        .pad__header {
            padding: 15px 20px;
            .title-main {
                margin-right: 0;
            }
        }
        .pad__list {
            .item {
                padding: 20px 20px 12px 20px;
            }
        }
        .pad__message {
            padding: 25px 20px 25px 20px;
        }
        .pad__polling {
            padding: 25px 15px 25px 15px;
        }
    }
    .form-login {
        padding: 15px 20px;
        button {
            width: 100%;
        }
    }
}
@media (max-width: 525px) {
    .section--inbox {
        .pad__header {
            .brand-logo {
                width: 203px;
                height: 48px;
            }
            .title-main {
                font-size: 20px;
                line-height: 24px;
                margin-top: 10px;
                img {
                    height: 24px;
                    width: auto;
                    margin-top: -3px;
                }
                .tag {
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                    font-size: 12px;
                    line-height: 24px;
                }
            }
        }
        .pad__list {
            .item {
                .box-avatar {
                    width: 100px;
                    height: 100px;
                    background-size: 100px 100px;
                    padding: 6px;
                    margin-right: 20px;
                }
                .box-content {
                    width: calc(100% - 100px - 20px);
                    padding-top: 10px;
                    strong {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media (max-width: 440px) {
    .section--inbox {
        .pad__header {
            text-align: center;
            .brand-logo {
                float: none;
                margin: 0 auto 15px auto;
            }
            .title-main {
                float: none;
                margin: 0 auto;
                img {
                    display: none;
                }
            }
        }
        .pad__list {
            .item {
                .box-avatar {
                    float: none;
                    margin: 0 auto 15px auto;
                }
                .box-content {
                    float: none;
                    width: 100%;
                    text-align: center;
                    strong {
                        margin: 10px auto;
                    }
                }
            }
        }
        .pad__content {
            padding: 25px 15px;
            .box-avatar {
                max-width: 250px;
                height: 250px;
                background-size: cover;
                padding: 16px;
                margin-bottom: 20px;
            }
            .box-content {
                strong {
                    font-size: 24px;
                    line-height: 36px;
                    margin-bottom: 10px;
                }
                p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 10px;
                }
            }
        }
        .pad__message {
            article {
                h1 {
                    font-size: 24px;
                    line-height: 36px;
                    margin-bottom: 15px;
                }
            }
        }
        .pad__polling {
            strong {
                font-size: 21px;
                margin-bottom: 20px;
            }
            .item {
                padding: 15px 15px;
                .ui-radio {
                    + {
                        label {
                            font-size: 14px;
                            padding-left: 30px;
                        }
                    }
                }
            }
            .btn-square {
                font-size: 21px;
            }
        }
    }
}
@media (max-width: 767px) {
    .col-lg-6 {
        &:first-child {
            margin-bottom: 20px;
        }
    }
}
